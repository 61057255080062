/* display */

$displayProps: (
  "block",
  "inline-block",
  "inline",
  "flex",
  "grid",
  "inline-flex",
  "inline-grid",
  "table",
  "inline-table",
  "list-item",
  "none",
  "contents"
);

@each $disp in $displayProps {
  .display-#{$disp} {
    display: #{$disp};
  }
}

/* end display */

/* width */
$widthStart: 0;
$widthEnd: 100;
@for $perc from $widthStart through $widthEnd {
  .width-perc-#{$perc} {
    width: #{($perc)}+'%';
  }
}
/* end width */

/* HEIGTH */
$heightStart: 0;
$heightEnd: 100;
@for $perc from $heightStart through $heightEnd {
  .height-perc-#{$perc} {
    height: #{($perc)}+'%';
  }
}

/* flexbox */

  
  $alignments: ("center", "baseline", "space-between", "space-around", "space-evenly", "flex-start", "flex-end", "end", "stretch");
  
  @each $align in $alignments {
    .justify-content-#{$align} {
      justify-content: #{$align};
    }

    .align-items-#{$align} {
        align-items: #{$align};
    }
}
/* end flexbox */

/* shrink */
$shrinkStart: 0;
$shrinkEnd: 2;
@for $shrink from $shrinkStart through $shrinkEnd {
  .flex-shrink-#{$shrink} {
    flex-shrink: #{$shrink};
  }
}
/* end shrink*/

/* margin */

$marginStartPx: 0;
$marginEndPx: 100;

@for $margin from $marginStartPx through $marginEndPx {
  .margin-top-#{$margin}-px {
      margin-top: #{($margin)}px;
  }
  .margin-bottom-#{$margin}-px {
    margin-bottom: #{($margin)}px;
  }
  .margin-left-#{$margin}-px {
    margin-left: #{($margin)}px;
  }
  .margin-right-#{$margin}-px {
    margin-right: #{($margin)}px;
  }
  .margin-#{$margin}-px {
    margin: #{($margin)}px;
  }
}
/* end margin */

/* padding */

$paddingStartPx: 0;
$paddingEndPx: 100;

@for $padding from $paddingStartPx through $paddingEndPx {
  .padding-top-#{$padding}-px {
    padding-top: #{($padding)}px;
  }
  .padding-bottom-#{$padding}-px {
    padding-bottom: #{($padding)}px;
  }
  .padding-left-#{$padding}-px {
    padding-left: #{($padding)}px;
  }
  .padding-right-#{$padding}-px {
    padding-right: #{($padding)}px;
  }
  .padding-#{$padding}-px {
    padding: #{($padding)}px;
  }
}
/* end padding */

/*color background*/
.transparent {
  background-color: unset !important;
}

.red {
  background-color: rgb(255 0 0 / 26%) !important;
}
.orange {
  background-color: rgb(255 165 0 / 26%) !important;
}
.yellow {
  background-color: rgb(255 255 0 / 26%) !important;   
}
.green {
  background-color: rgb(68 255 0 / 26%) !important;   
}

/*end color background*/

/*cursor*/

  
$cursors: ("alias", "all-scroll", "auto", "cell", "col-resize", "context-menu", "copy", "crosshair", "default", "none", "pointer");
  
@each $cursor in $cursors {
  .cursor-#{$cursor} {
    cursor: #{$cursor};
  }
}

/*end cursor*/

.activeTask {
  border: 2px solid black;
  padding: 20px;
  margin: 20px;
  background: whitesmoke;
  font-size: xxx-large;
  color: #f44336;
}

.dropbox {
  height: 250px;
  margin: auto;
  border: 2px solid #DDD;
  border-radius: 8px;
  background-color: whitesmoke;
  text-align: center;
  color: #BBB;

  > .label {
    flex-grow: 1;
    align-self: center;
  }
}

.iconFile {
  padding: 30px !important;
  min-height:100%;
}

.amt-form{
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  row-gap: 10px;
  column-gap: 20px;
}

.success-snackbar {
  --mdc-snackbar-container-color: #91dca3;
}

.error-snackbar {
  --mdc-snackbar-container-color: rgb(246, 153, 153);
}

.warn-snackbar {
  --mdc-snackbar-container-color:#f9c27e;
}