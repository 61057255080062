/* You can add global styles to this file, and also import other style files */

html, body { height: 100%; }
body { margin: 0; font-family: Roboto, "Helvetica Neue", sans-serif; }

.main-container {
    display: flex;
    flex-direction: column;
    position: absolute;
    top: 60px;
    bottom: 0;
    left: 0;
    right: 0;

    .navbar {
        position: fixed;
        top:0;
        width: 100%;
        height: 60px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        color: white;
        padding: 0 16px 0 16px;
    
        & > div {
            line-height: 60px;
        }
    
        .app-title {
            img {
                width: 60px;
                height: 60px;
                vertical-align: middle;
            }
            & > span {
                padding-left: 16px;
            }
        }
    
        .user-info {
            span.btn {
                margin-left: 16px;
                cursor: pointer;
                padding: 8px 16px;
                border: 1px solid white;
                border-radius: 4px;
                
    
                &:hover {
                    background-color: white;
                    border-color: #2A2741;
                    color: #2A2741;
                }
            }
        }
    }
}

/* OVERLAY SPINNER */
.overlay-spinner {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  
/* END OVERLAY SPINNER */


/* TABLE */
.app-table {
    // overflow: auto;
    height: 100%;
    margin: 16px;
    table {
        white-space: nowrap;
    }
}

.table-responsive {
    // display: block;
    // width: 100%;
    overflow-x: auto;
  
    /* .mat-table {
      width: 100%;
      max-width: 100%;
      margin-bottom: 1rem;
      display: table;
      border-collapse: collapse;
      margin: 0px;
    }
  
    .mat-mdc-table {
      white-space: pre
    }
  
    .mat-row,
    .mat-header-row {
      display: table-row;
    }
  
    .mat-cell,
    .mat-header-cell {
      word-wrap: initial;
      display: table-cell;
      padding: 0px 5px;
      line-break: unset;
      width: auto;
      white-space: pre;
      overflow: hidden;
      vertical-align: middle;
    }*/
  }

.table-spinner-container {
    display: flex;
    margin: 8px;
    justify-content: center;
    align-items: center;
    // background-color: #fafafa; 
    // box-shadow:0 5px 5px -3px rgba(0,0,0,.2), 0 8px 10px 1px rgba(0,0,0,.14), 0 3px 14px 2px rgba(0,0,0,.12)
}

.table-nodata {
    &--hidden {
        display: none !important;
    }
    &_content {
      display: flex;
      justify-content: center;
      padding: 24px;
      font-size: 21px;
      align-items: center;
    }
  }

.month-select {
    width: 200px !important;
}
  